<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-row>
            <v-col cols="12" sm="4" lg="3">
              <month-picker v-model="selectedDate" />
            </v-col>
            <v-col cols="12" sm="8" lg="9" class="text-right">
              <id-button @click="onRefreshData">
                <template #icon>
                  <v-icon left>mdi-refresh</v-icon>
                </template>
                {{ $t("buttons.refresh") }}
              </id-button>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          hide-default-footer
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-xs-left no-wrap">
                {{ $helpers.formatDate(row.item.date, "DD MMMM YYYY") }}
              </td>
              <td class="text-xs-center">
                <v-chip small class="ma-2" :color="$helpers.getStatusTypeColor(row.item)" label text-color="white">
                  <v-icon left size="small">{{ $helpers.getTypeIcon(row.item) }}</v-icon>
                  {{ $helpers.getTypeName(row.item) }}
                </v-chip>
              </td>
              <td class="text-xs-left">
                {{ $helpers.formattedClock(row.item.timesheet.clockInTimestamp) }}
              </td>
              <td class="text-xs-left">
                {{ $helpers.formattedClock(row.item.timesheet.clockOutTimestamp) }}
              </td>

              <td class="text-xs-left">
                {{ $helpers.convertMinutesToTime(row.item.timesheet.shift.expectedWorkingTimeInMinutes) }}
              </td>
              <td class="text-xs-left ">
                {{ $helpers.convertMinutesToTime(row.item.timesheet.actualWorkingTimeInMinutes) }}
              </td>
              <td class="text-xs-left no-wrap">
                <template v-if="row.item.overtimeDisabled">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">
                        mdi-calendar-remove
                      </v-icon>
                    </template>
                    <span class="font-weight-bold">{{ $t("shift.not_included_overtime_calculation") }}</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ $helpers.convertMinutesToTime(row.item.timesheet.totalOvertimeInMinutes) }}
                </template>
              </td>
              <td class="text-xs-left no-wrap">
                <template v-if="row.item.missingTimeDisabled">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">
                        mdi-calendar-remove
                      </v-icon>
                    </template>
                    <span class="font-weight-bold">
                      {{ $t("shift.not_included_missing_time_calculation") }}
                    </span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ $helpers.convertMinutesToTime(row.item.timesheet.totalMissingTimeInMinutes) }}
                </template>
              </td>
            </tr>
          </template>
          <template #body.append="{headers}">
            <tr>
              <td colspan="4" class="font-weight-bold primary--text">
                {{ $t("time.timesheet.total") }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinutesToTime(table.expectedWorkingTimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinutesToTime(table.actualWorkingTimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinutesToTime(table.totalOvertimeInMinutes) }}
              </td>
              <td class="font-weight-bold primary--text">
                {{ $helpers.convertMinutesToTime(table.totalMissingTimeInMinutes) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { WORKPLANS_PAGINATE, FILTER_HOLIDAYS } from "../query";
  import TimesheetRow from "./../model/TimesheetRow";

  export default {
    name: "Timesheets",
    data: vm => ({
      selectedDate: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [vm.$store.state.auth.user.id],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {
          page: 1,
          itemsPerPage: 31,
          sortBy: ["date"],
          sortDesc: [false]
        },
        totalItems: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("time.timesheet.date"),
            title: vm.$t("time.timesheet.date"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.status"),
            title: vm.$t("time.timesheet.status"),
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.clock_in"),
            title: vm.$t("time.timesheet.clock_in_title"),
            value: "clockInTimestamp",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.clock_out"),
            title: vm.$t("time.timesheet.clock_out_title"),
            value: "clockOutTimestamp",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },

          {
            text: vm.$t("time.timesheet.ewt"),
            title: vm.$t("time.timesheet.ewt_title"),
            value: "expectedWorkingTimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.awt"),
            title: vm.$t("time.timesheet.awt_title"),
            value: "actualWorkingTimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.ot"),
            title: vm.$t("time.timesheet.ot_title"),
            value: "totalOvertimeInMinutes",
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          },
          {
            text: vm.$t("time.timesheet.mt"),
            value: "totalMissingTimeInMinutes",
            title: vm.$t("time.timesheet.mt_title"),
            align: "left",
            sortable: false,
            class: ["primary--text"],
            width: ""
          }
        ],
        expectedWorkingTimeInMinutes: 0,
        actualWorkingTimeInMinutes: 0,
        totalOvertimeInMinutes: 0,
        totalMissingTimeInMinutes: 0
      },
      filtering: false,
      holidays: []
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      selectedDate: {
        handler(value) {
          this.table.criteria.beginDate = this.$moment(value)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(value)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.fetchHolidays();
        this.$apollo
          .query({
            query: WORKPLANS_PAGINATE,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.toPaginationCriteria(this.table.options)
            },
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = [];
              data.filterWithPaginateWorkplans.content.forEach(currentWorkplan => {
                if (currentWorkplan.calculatedTimesheet) {
                  let timesheetRowModel = new TimesheetRow();
                  let currentWorkplanTimesheet = currentWorkplan.calculatedTimesheet;
                  timesheetRowModel.id = currentWorkplan.id;
                  timesheetRowModel.date = currentWorkplan.date;
                  timesheetRowModel.timesheet.id = currentWorkplanTimesheet.id;
                  timesheetRowModel.timesheet.shift.time =
                    currentWorkplan.assignedShifts.length === 0
                      ? "-- : --"
                      : currentWorkplanTimesheet.shift.shiftType === "FIX"
                      ? currentWorkplanTimesheet.shift.beginTime + " - " + currentWorkplanTimesheet.shift.endTime
                      : this.$helpers.convertMinutesToTime(currentWorkplanTimesheet.shift.totalWorkingTimeInMinutes);
                  timesheetRowModel.timesheet.shift.expectedWorkingTimeInMinutes =
                    currentWorkplan.assignedShifts.length !== 0
                      ? currentWorkplanTimesheet.shift.expectedWorkingTimeInMinutes
                      : 0;
                  timesheetRowModel.timesheet.shift.type =
                    currentWorkplan.assignedShifts.length === 0 ? "OFF_DAY" : currentWorkplanTimesheet.shift.shiftType;
                  timesheetRowModel.timesheet.actualWorkingTimeInMinutes =
                    currentWorkplanTimesheet.actualWorkingTimeInMinutes;
                  timesheetRowModel.timesheet.clockInTimestamp = currentWorkplanTimesheet.clockInTimestamp;
                  timesheetRowModel.timesheet.clockOutTimestamp = currentWorkplanTimesheet.clockOutTimestamp;
                  timesheetRowModel.assignedShifts = currentWorkplan.assignedShifts;
                  timesheetRowModel.countAsOvertime = currentWorkplan.countAsOvertime;
                  timesheetRowModel.leaveRequest = currentWorkplan.leaveRequest;
                  timesheetRowModel.timesheet.totalOvertimeInMinutes = currentWorkplanTimesheet.totalOvertimeInMinutes;
                  timesheetRowModel.timesheet.totalMissingTimeInMinutes =
                    currentWorkplanTimesheet.totalMissingTimeInMinutes;
                  this.table.data.push(timesheetRowModel);
                } else {
                  let timesheetRowModel = new TimesheetRow();
                  const assignedShift = currentWorkplan.assignedShifts[0];
                  timesheetRowModel.id = currentWorkplan.id;
                  timesheetRowModel.date = currentWorkplan.date;
                  timesheetRowModel.timesheet.shift.expectedWorkingTimeInMinutes = assignedShift
                    ? assignedShift.expectedWorkingTimeInMinutes
                    : 0;
                  timesheetRowModel.assignedShifts = currentWorkplan.assignedShifts;
                  timesheetRowModel.countAsOvertime = currentWorkplan.countAsOvertime;
                  timesheetRowModel.leaveRequest = currentWorkplan.leaveRequest;
                  if (assignedShift && assignedShift.shiftType === "FIX") {
                    const holiday = this.holidays.find(
                      holiday => holiday.date === currentWorkplan.date && holiday.halfDay
                    );
                    if (holiday) {
                      const dateObj = this.$moment("12:00", "HH:mm");
                      const shiftDateObj = this.$moment(assignedShift.beginTime, "HH:mm");
                      if (shiftDateObj.isSameOrAfter(dateObj)) {
                        timesheetRowModel.countAsOvertime = true;
                      }
                    }
                  }

                  this.table.data.push(timesheetRowModel);
                }
              });
              this.findTotals(data.filterWithPaginateWorkplans.content);
              this.table.totalItems = data.filterWithPaginateWorkplans.pageInfo.totalElements || 0;
            }
          })
          .catch(console.error)
          .finally(() => (this.table.loading = false));
      },
      findTotals(workplans) {
        this.table.actualWorkingTimeInMinutes = 0;
        this.table.expectedWorkingTimeInMinutes = 0;
        this.table.totalOvertimeInMinutes = 0;
        this.table.totalMissingTimeInMinutes = 0;

        workplans.forEach(workplan => {
          if (workplan.calculatedTimesheet) {
            this.table.actualWorkingTimeInMinutes += workplan.calculatedTimesheet.actualWorkingTimeInMinutes;
            this.table.expectedWorkingTimeInMinutes += workplan.calculatedTimesheet.shift
              ? workplan.calculatedTimesheet.shift.expectedWorkingTimeInMinutes
              : 0;
            this.table.totalOvertimeInMinutes += workplan.calculatedTimesheet.totalOvertimeInMinutes;
            this.table.totalMissingTimeInMinutes += workplan.calculatedTimesheet.totalMissingTimeInMinutes;
          } else {
            const assignedShift = workplan.assignedShifts[0];
            this.table.expectedWorkingTimeInMinutes += assignedShift ? assignedShift.expectedWorkingTimeInMinutes : 0;
          }
        });
      },
      fetchHolidays() {
        return this.$apollo
          .query({
            query: FILTER_HOLIDAYS,
            variables: {
              criteria: {
                beginDate: this.table.criteria.beginDate,
                endDate: this.table.criteria.endDate
              }
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.holidays = data.filterHolidays;
            }
          })
          .catch(console.error);
      }
    }
  };
</script>

<style scoped></style>
